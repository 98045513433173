import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSlider } from 'vuetify/lib/components/VSlider';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c('div',{staticStyle:{"width":"100%"}},[_c('canvas',{attrs:{"id":"previewContainer"}}),_c('canvas',{style:({ display: _vm.showFocus ? 'block' : 'none' }),attrs:{"id":"focusContainer"}})]),_c(VCardTitle,{staticClass:"mx-1 pb-0"},[_c(VSlider,{attrs:{"min":"0","max":_vm.max,"append-icon":"mdi-step-forward","prepend-icon":"mdi-step-backward"},on:{"click:append":_vm.forward,"click:prepend":_vm.backward,"input":_vm.updateImage,"end":_vm.preloadImages},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1),_c(VCardTitle,[_c(VImg,{staticClass:"mr-2",staticStyle:{"border-radius":"6px"},attrs:{"max-width":"25","max-height":"25","src":_vm.weatherIcon}}),_vm._v(" "+_vm._s(_vm.weatherString)+" ")],1),_c(VCardTitle,{staticClass:"mx-1 pt-0 text-subtitle-1"},[_vm._v(" Archive from:  "),_c('strong',[_vm._v(_vm._s(_vm.currentDatetime))]),_c(VBtn,{staticClass:"ml-3",attrs:{"icon":"","color":_vm.showFocus ? 'warning' : 'info'},on:{"click":function($event){_vm.showFocus=!_vm.showFocus}}},[_c(VIcon,[_vm._v("mdi-image-filter-center-focus")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }